<template>
	<div class="farmdetail">
		<v-container>
			<v-row align="center" justify="center">
				<v-btn
					icon
					elevation="2"
					outlined
					class="ma-2"
					@click="$router.push('listfarm')"
				>
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
				<h1>{{ farm_name }}</h1>
				<div class="mx-2"></div>
			</v-row>
		</v-container>
		<v-row no-gutters>
			<v-col
				v-for="green_house_item in green_house"
				:key="green_house_item.green_house_id"
				md="8"
				offset-md="2"
			>
				<v-card
					v-if="green_house_item.active == 1"
					class="mx-auto pa-2"
					min-width="344"
					outlined
					@click="farm(green_house_item)"
				>
					<v-list-item three-line>
						<v-img
							:src="img"
							height="100px"
							width="10px"
							contain
						></v-img>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								{{ green_house_item.green_house_name }}
							</v-list-item-title>
							<v-list-item-subtitle>
								<span class="orange--text">
									{{
										green_house_item.veg_table_count
									}}
									โต๊ะปลูก </span
								><br />
								<span
									class="blue--text"
									v-if="green_house_item.temp"
								>
									{{ green_house_item.temp }}°C
								</span>
								<span
									class="blue--text"
									v-if="green_house_item.soil_moisture"
								>
									{{ green_house_item.soil_moisture }}%
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "farmdetail",
	data: () => ({
		img: require("../assets/greenhouse (1).png"),
		farm_name: "",
		green_house: [],
	}),
	created: function () {
		window.scrollTo(0, 0);
		this.farm_name = this.$session.get("farm_data").farm_name;
		var self = this;
		var config = {
			method: "get",
			url: "https://cool-agri.com/hydroponic_portal/api/v1/green_house",
			headers: {
				Authorization: localStorage.getItem("jwt"),
				"Content-Type": "application/json",
			},
			params: { farm_id: this.$session.get("farm_data").farm_id },
		};
		this.axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success == 1) {
					self.green_house = response.data.data;
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$router.push("/");
			});
	},
	methods: {
		farm(green_house_item) {
			this.$session.set("green_house_item", green_house_item);
			this.$router.push("/farm");
		},
	},
};
</script>

<style></style>
